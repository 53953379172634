import React from "react";
import {
  Grid,
  Switch,
  FormGroup,
  FormLabel,
  FormControl,
  FormControlLabel,
  Select,
  Chip,
  MenuItem,
  Input,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WebMAPToolTip from "components/WebMAPToolTip";

const ChipsWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
}));

class SearchSettings extends React.PureComponent {
  state = {
    showSearchSourcesFilter: this.props.searchSources.length > 0 ? true : false,
  };

  localUpdateSearchOptions = (name, value) => {
    const { searchOptions } = this.props;
    // Send the new values up to the Search component's state
    this.props.updateSearchOptions({ ...searchOptions, [name]: value });
  };

  render() {
    const { searchOptions, searchSources, searchModel } = this.props;
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs>
          <FormControl component="fieldset">
            <FormLabel component="legend">Общи настройки за търсене</FormLabel>
            <FormGroup>
              <WebMAPToolTip title="Включете, за да изберете кои източници на данни ще се търсят. Ако превключвателят е изключен, търсенето ще се извършва във всички налични източници на данни.">
                <FormControlLabel
                  label="Ограничаване на източниците на търсене"
                  control={
                    <Switch
                      checked={this.state.showSearchSourcesFilter}
                      onChange={(e) => {
                        // Pull out the new value
                        const showSearchSourcesFilter = e.target.checked;

                        // Set state to reflect in Switch's UI
                        this.setState({
                          showSearchSourcesFilter,
                        });

                        // Now, if user has turned off this setting, ensure
                        // that we also clean all search sources
                        if (showSearchSourcesFilter === false)
                          this.props.setSearchSources([]);
                      }}
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
              {this.state.showSearchSourcesFilter && (
                <Grid container spacing={2}>
                  <Grid item xs>
                    <Select
                      fullWidth
                      labelId="demo-mutiple-chip-label"
                      multiple
                      value={searchSources}
                      onChange={(event) =>
                        this.props.setSearchSources(event.target.value)
                      }
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <ChipsWrapper>
                          {selected.map((option) => (
                            <Chip
                              key={option.id}
                              label={option.caption}
                              sx={{ margin: 0.25 }}
                            />
                          ))}
                        </ChipsWrapper>
                      )}
                    >
                      {searchModel
                        .getSources()
                        .sort((a, b) => a.caption.localeCompare(b.caption))
                        .map((source) => (
                          <MenuItem key={source.id} value={source}>
                            {source.caption}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                </Grid>
              )}
            </FormGroup>
            <FormGroup>
              <WebMAPToolTip title="Ако е активно, търсенето ще се извършва в хранилища, които са зададени за търсене от системния администратор и са видими.">
                <FormControlLabel
                  label="Търсене само във видимите слоеве"
                  control={
                    <Switch
                      checked={searchOptions.searchInVisibleLayers}
                      onChange={() => {
                        this.localUpdateSearchOptions(
                          "searchInVisibleLayers",
                          !searchOptions.searchInVisibleLayers
                        );
                      }}
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Настройки за търсене на текст
            </FormLabel>
            <FormGroup>
              <WebMAPToolTip title="Ако е активно, търсенето на 'път' ще даде резултати например за 'главен път'.">
                <FormControlLabel
                  label="Wildcard преди"
                  control={
                    <Switch
                      checked={searchOptions.wildcardAtStart}
                      onChange={() =>
                        this.localUpdateSearchOptions(
                          "wildcardAtStart",
                          !searchOptions.wildcardAtStart
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
              <WebMAPToolTip title="Ако е активно, търсенето на 'път' ще даде резултати и за, например, 'пътища'.">
                <FormControlLabel
                  label="Wildcard след"
                  control={
                    <Switch
                      checked={searchOptions.wildcardAtEnd}
                      onChange={() =>
                        this.localUpdateSearchOptions(
                          "wildcardAtEnd",
                          !searchOptions.wildcardAtEnd
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
              <WebMAPToolTip title="Ако е активно, търсенето на 'a' няма да върне резултати за 'A'. Деактивирайте, за да търсите независимо от малки/големи букви.">
                <FormControlLabel
                  label="Чувствителност към малки/главни букви"
                  control={
                    <Switch
                      checked={searchOptions.matchCase}
                      onChange={() =>
                        this.localUpdateSearchOptions(
                          "matchCase",
                          !searchOptions.matchCase
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              Настройки за пространствено търсене
            </FormLabel>
            <FormGroup>
              <WebMAPToolTip title="Ако е активен, целият обект (напр. имот) трябва да се намира в зоната за търсене, за да бъде включен в резултата. Ако е неактивен, достатъчно е само малка част от обекта да попада в зоната за търсене или да я докосва.">
                <FormControlLabel
                  label="Изискване целият обект да се вмества в зоната за търсене"
                  control={
                    <Switch
                      checked={searchOptions.activeSpatialFilter === "within"}
                      onChange={() =>
                        this.localUpdateSearchOptions(
                          "activeSpatialFilter",
                          searchOptions.activeSpatialFilter === "intersects"
                            ? "within"
                            : "intersects"
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
            </FormGroup>
          </FormControl>
        </Grid>

        <Grid item xs>
          <FormControl component="fieldset">
            <FormLabel component="legend">Показване на резултатите</FormLabel>
            <FormGroup>
              <WebMAPToolTip title="Ако е активно, в картата ще се появи етикет до избрания резултат от търсенето">
                <FormControlLabel
                  label="Показване на текстов етикет в картата"
                  control={
                    <Switch
                      checked={searchOptions.enableLabelOnHighlight}
                      onChange={() =>
                        this.localUpdateSearchOptions(
                          "enableLabelOnHighlight",
                          !searchOptions.enableLabelOnHighlight
                        )
                      }
                      color="primary"
                    />
                  }
                />
              </WebMAPToolTip>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>
    );
  }
}

export default SearchSettings;
